import {createCMSDefaultTheme} from "@camberi/firecms";

export const defaultTheme = createCMSDefaultTheme({
    mode: 'light',
    primaryColor:
        process.env.REACT_APP_ENV == 'prod'
            ? '#003764'
            : '#ef6666',
    secondaryColor:
        process.env.REACT_APP_ENV == 'prod'
            ? '#007db2'
            : '#facccc',
    fontFamily: 'Roboto, sans-serif',
})