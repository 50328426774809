import {buildSchema} from '@camberi/firecms'
import {Program} from '../../types/events/program.types'

export const programSchema = buildSchema<Program>({
    name: 'Programm',
    description: 'Programmpunkte angezeigt unter der Kategorie "Program" sortiert nach Startzeit.',
    defaultValues: {
        published: true,
        canBeRegisteredFor: false,
    },
    properties: {
        startTime: {
            title: 'Startzeit',
            validation: {required: true},
            dataType: 'timestamp',
        },
        published: {
            title: 'Anzeigen',
            validation: {required: true},
            dataType: 'boolean',
        },
        title: {
            title: 'Titel',
            validation: {required: true, min: 1, max: 120},
            dataType: 'string',
        },
        description: {
            title: 'Beschreibung',
            validation: {required: false, min: 1, max: 1500},
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        location: {
            title: 'Ort',
            validation: {required: false, min: 1, max: 120},
            dataType: 'string',
        },
        iconName: {
            title: 'Mat-Icon Name',
            description: 'ID des Icons von Material Design Icons',
            validation: {required: false, min: 1, max: 120},
            dataType: 'string',
        },
    },
})
