import { buildCollection } from '@camberi/firecms'
import { discountSchema } from '../schemas/discount.schema'

export const discountBuildCollection = buildCollection({
    group: 'Vergünstigungen',
    path: 'discounts',
    schema: discountSchema,
    name: 'Vergünstigungen',
    defaultSize: 'xs',
    initialSort: ['name', 'asc'],
    textSearchEnabled: true,
    excludedProperties: [
        'website',
        'description',
        'isOnline',
        'addresses',
        'logo',
        'sendPushNotificationToUser',
    ],
    pagination: true,
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                dateAdded: new Date(),
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
})
