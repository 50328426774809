import { buildCollection } from '@camberi/firecms'
import { faqSchema } from '../schemas/faq.schema'

export const faqCollection = buildCollection({
    group: 'Personalverein',
    path: 'faq',
    schema: faqSchema,
    name: 'FAQ',
    defaultSize: 'xs',
    initialSort: ['sortOrder', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
