import {buildCollection, EntityOnSaveProps, EntityValues} from '@camberi/firecms'
import {associationsSchema} from "../schemas/associations/associations.schema";
import {eventSchema} from "../schemas/events/event.schema";
import {mainSettingsSchema} from "../schemas/events/settings.schema";
import {signupSchema} from "../schemas/events/signup.schema";
import {programSchema} from "../schemas/events/program.schema";
import {menuSchema} from "../schemas/events/menu.schema";
import {activitySchema} from "../schemas/events/activity.schema";
import {faqSchema} from "../schemas/events/faq.schema";
import {partnerSchema} from "../schemas/events/partner.schemas";
import {impressionSchema} from "../schemas/events/impression.schema";
import {siteplanSchema} from "../schemas/events/siteplan.schema";
import {statisticSchema} from "../schemas/events/statistic.schema";
import {memberSchema} from "../schemas/associations/member.schema";

export const associationsCollection = buildCollection({
    group: 'Vereine',
    path: 'associations',
    schema: associationsSchema,
    name: 'Vereine',
    defaultSize: 'm',
    initialSort: ['name', 'asc'],
    pagination: true,
    subcollections: [{
        path: 'events', name: 'Events', schema: eventSchema, subcollections: [{
            path: 'settings', name: 'Einstellungen', schema: mainSettingsSchema,
        }, {
            path: 'signups', name: 'Anmeldungen', schema: signupSchema, initialSort: ['signedUpDate', 'desc'],
        }, {
            path: 'program', name: 'Programm', schema: programSchema, initialSort: ['startTime', 'asc'],
        }, {
            path: 'menu', name: 'Menü', schema: menuSchema, initialSort: ['sortOrder', 'asc'],
        }, {
            path: 'activities', name: 'Aktivitäten', schema: activitySchema, initialSort: ['sortOrder', 'asc'],
        }, {
            path: 'faq', name: 'FAQ', schema: faqSchema, initialSort: ['sortOrder', 'asc'],
        }, {
            path: 'partners', name: 'Partner', schema: partnerSchema, initialSort: ['sortOrder', 'asc'],
        }, {
            path: 'impressions', name: 'Impressionen', schema: impressionSchema, initialSort: ['sortOrder', 'asc'],
        }, {
            path: 'siteplans', name: 'Lagepläne', schema: siteplanSchema, initialSort: ['sortOrder', 'asc'],
        }, {
            path: 'statistics', name: 'Statistiken', schema: statisticSchema,
        }],
    }, {
        path: 'members', name: 'Mitglieder', schema: memberSchema, initialSort: ['joined', 'desc'],
    }],
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
                created: props.values.created || new Date(),
                updated: new Date()
            }
        }
    }
});