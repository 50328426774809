import {buildProperty, buildSchema} from '@camberi/firecms'
import {Association} from "../../types/associations/association.types";

export const associationsSchema = buildSchema<Association>({
    name: 'Vereine', properties: {
        name: {
            title: 'Name', validation: {required: true, min: 1, max: 120}, dataType: 'string',
        }, abbreviation: {
            title: 'Abkürzung', validation: {required: true, min: 1, max: 20, unique: true}, dataType: 'string',
        }, description: {
            title: 'Beschreibung', validation: {required: true, min: 1, max: 1500}, dataType: 'string',
        }, logo: buildProperty({
            title: 'Logo', dataType: 'string', config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/events',
                    validation: {required: true, min: 1, max: 300},
                    acceptedFiles: ['image/*'],
                },
            },
        }), website: {
            title: 'Website', validation: {required: true, min: 1, max: 300}, dataType: 'string',
        }, created: {
            title: 'Erstellt', dataType: 'timestamp', readOnly: true, autoValue: 'on_create'
        }, updated: {
            title: 'Aktualisiert', dataType: 'timestamp', readOnly: true, autoValue: 'on_update'
        },
    },
})
