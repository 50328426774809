import {buildSchema} from '@camberi/firecms'
import {Statistic} from '../../types/events/statistic.types'

export const statisticSchema = buildSchema<Statistic>({
    name: 'Statistiken',
    properties: {
        viewCount: {
            title: 'Aufrufe',
            description: "Die Anzahl, wie oft das Event aufgerufen wurde",
            dataType: 'number',
            validation: {required: true, min: 0},
            readOnly: true,
        },
        signOutsCount: {
            title: 'Abmeldungen',
            description: "Die Anzahl, wie oft sich Benutzer abgemeldet haben",
            dataType: 'number',
            validation: {required: true, min: 0},
            readOnly: true,
        },
    },
})
