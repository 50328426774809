import { buildCollection } from '@camberi/firecms'
import { appsettingSchema } from '../schemas/appsetting.schema'

export const settingsCollection = buildCollection({
    group: 'Verwaltung',
    path: 'settings',
    schema: appsettingSchema,
    name: 'Einstellungen',
    permissions: ({ authController }) => ({
        edit: true,
        create: false,
        delete: false,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
