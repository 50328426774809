import { buildCollection } from '@camberi/firecms'
import { eventSchema } from '../schemas/event.schema'

export const eventsCollection = buildCollection({
    group: 'Personalverein',
    path: 'events',
    schema: eventSchema,
    defaultSize: 'xs',
    initialSort: ['dateStart', 'desc'],
    pagination: true,
    name: 'Events',
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    excludedProperties: ['sendPushNotificationToUser'],
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
