import { FieldProps, GeoPoint } from "@camberi/firecms";
import {Button, TextField} from "@mui/material";
import {fetchGeopoint} from "../api/geo-point";
import * as leaflet from 'leaflet';
import {useEffect, useState} from "react";
import {CSSProperties} from "@mui/styles";

export function GeopointField({ value, setValue, context, initialValue}: FieldProps<GeoPoint>) {

    let locationName = context.values.name;
    let streetName = context.values.street;
    let plz = context.values.plz;
    let city = context.values.city
    let long: number;
    let lat: number;
    let discountMap: leaflet.Map;

    const [cardHasBeenInit = false, setCardHasBeenInit] = useState<boolean>()
    const [mapStyle, setMapStyle] = useState<CSSProperties>();
    const [marker, setMarker] = useState<leaflet.Marker>();
    useEffect(() => {updateMarkerOnMap()}, [marker])

    async function getGeoPoint() {
        if (locationName && streetName && plz && city) {
            try {
                value = await fetchGeopoint(context.values)
                setValue(value)
                if (!cardHasBeenInit) {
                    initMap()
                } else if (cardHasBeenInit) {
                    updateMarkerOnMap()
                }
            } catch {
                console.error("Could not generate Geopoint")
            }
        } else {
            console.error("Give propper Values")
        }
    }

    function initMap() {
        setCardHasBeenInit(true)
        setMapStyle({height: "220px"})
            discountMap = leaflet.map('map', {
                center: [47.05376075, 8.32039165],
                zoom: 8,
                maxBounds: [
                    [-90, -180],
                    [90, 180],
                ],
            });

            const tiles = leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                minZoom: 3,
                noWrap: true,
            });

            tiles.addTo(discountMap);
        const geoMarker = leaflet.marker([value.latitude, value.longitude])
        setMarker(geoMarker)

        geoMarker.addTo(discountMap);
    }

    function updateMarkerOnMap() {
        if(!!value?.longitude && !!value?.latitude) {
            setMarker(marker!.setLatLng([value.latitude, value.longitude]))
        }
    }

    function handleChangeLong(longValue: string) {
        long = parseFloat(longValue);
        lat = value.latitude;
        setValue(new GeoPoint(lat, long));
    }

    function handleChangeLat(latValue: string) {
        lat = parseFloat(latValue);
        long = value.longitude;
        setValue(new GeoPoint(lat, long));
    }

    return <>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
              integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
              crossOrigin=""/>
        <script src="https://unpkg.com/leaflet@1.9.2/dist/leaflet.js"
                integrity="sha256-o9N1jGDZrf5tS+Ft4gbIK7mYMipq9lqpVJ91xHSyKhg="
                crossOrigin=""></script>
        <div style={{paddingBottom: "32px", paddingTop: 0}}><Button onClick={() => getGeoPoint()}>Geopoint berechnen</Button></div>
        <div><TextField defaultValue={0} inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}} type={"number"} label={"Long"} value={value?.longitude} onChange={(evt: any) => {handleChangeLong(evt.target.value)}}></TextField>
        <TextField defaultValue={0} inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}} type={"number"} label={"Lat"} value={value?.latitude} onChange={(evt: any) => {handleChangeLat(evt.target.value)}}></TextField></div>
        <div style={{paddingTop: "4px"}}><Button onClick={() => updateMarkerOnMap()}>Marker updaten</Button></div>

        <div id="map" style={mapStyle}></div>
    </>
}                         