export enum allergies {
    gluten = 'Gluten',
    lactose = 'Laktose',
    nuts = 'Nüsse',
    fish = 'Fisch',
    shellfish = 'Schalentiere',
    eggs = 'Eier',
    soy = 'Soja',
    other = 'Andere',
}

export enum eatStyle {
    omnivore = 'omnivore',
    vegetarian = 'vegetarian',
    vegan = 'vegan',
    pescetarian = 'pesceatarian',
    other = 'other'
}