import { buildSchema } from '@camberi/firecms'
import { Role } from '../types/role.types'

export const roleSchema = buildSchema<Role>({
    name: 'Rolle',
    customId: true,
    properties: {
        name: {
            title: 'Name',
            dataType: 'string',
            columnWidth: 100,
        },
        canLogIn: {
            title: '🚪 App Login',
            dataType: 'boolean',
            description:
                'Bestimmt, ob sich ein Signup in der PV App anmelden kann.',
            longDescription:
                'Wenn ein Signup diese Berechtigung verliert, wird er direkt aus der App ausgeloggt!',
        },
        canSeeMembershipCard: {
            title: '✨ Mitgliederausweis',
            dataType: 'boolean',
            description:
                'Users mit dieser Berechtigung sehen ihren Mitgliederausweis in der PV App.',
        },
        canSeeDiscounts: {
            title: '✨ Vergünstigungen',
            dataType: 'boolean',
            description:
                'Bestimmt, ob ein Benutzer die Vergünstigungen in der App sehen kann',
        },
        canSeeEvents: {
            title: '✨ Events anzeigen',
            dataType: 'boolean',
            description:
                'Bestimmt, ob ein Benutzer Events in der App sehen kann',
        },
        canSeeCharityResults: {
            title: '✨ Spendenresultate anzeigen',
            dataType: 'boolean',
            description:
                'Bestimmt, ob ein Benutzer die Spendenresultate sehen kann.',
        },
        canDonatePvMoney: {
            title: '✨ PV-Betrag spenden',
            dataType: 'boolean',
            description:
                'Users mit dieser Berechtigung Können den PV Betrag spenden.',
        },

        // Firestore
        boardmembers_read: {
            title: '🔍 boardmembers',
            dataType: 'boolean',
        },
        boardmembers_write: {
            title: '📝 boardmembers',
            dataType: 'boolean',
        },
        discountAddresses_read: {
            title: '🔍 discountAddresses',
            dataType: 'boolean',
        },
        discountAddresses_write: {
            title: '📝 discountAddresses',
            dataType: 'boolean',
        },
        discounts_read: {
            title: '🔍 discounts',
            dataType: 'boolean',
        },
        discounts_write: {
            title: '📝 discounts',
            dataType: 'boolean',
        },
        events_read: {
            title: '🔍 events',
            dataType: 'boolean',
        },
        events_write: {
            title: '📝 events',
            dataType: 'boolean',
        },
        pushTopics_read: {
            title: '🔍 pushTopics',
            dataType: 'boolean',
        },
        pushTopics_write: {
            title: '📝 pushTopics',
            dataType: 'boolean',
        },
        userPushConfig_read: {
            title: '🔍 userPushConfig',
            dataType: 'boolean',
        },
        userPushConfig_read_own: {
            title: '🔍👤 userPushConfig',
            dataType: 'boolean',
        },
        userPushConfig_write: {
            title: '📝 userPushConfig',
            dataType: 'boolean',
        },
        userPushConfig_write_own: {
            title: '📝👤 userPushConfig',
            dataType: 'boolean',
        },
        roles_read: {
            title: '🔍 roles',
            dataType: 'boolean',
        },
        roles_write: {
            title: '📝 roles',
            dataType: 'boolean',
        },
        settings_read: {
            title: '🔍 settings',
            dataType: 'boolean',
        },
        settings_write: {
            title: '📝 settings',
            dataType: 'boolean',
        },
        settingsPublic_read: {
            title: '🔍 settingsPublic',
            dataType: 'boolean',
        },
        settingsPublic_write: {
            title: '📝 settingsPublic',
            dataType: 'boolean',
        },
        users_read: {
            title: '🔍 users',
            dataType: 'boolean',
        },
        users_read_own: {
            title: '🔍👤 users',
            dataType: 'boolean',
        },
        users_write: {
            title: '📝 users',
            dataType: 'boolean',
        },
        faq_read: {
            title: '🔍 faq',
            dataType: 'boolean',
        },
        faq_write: {
            title: '📝 faq',
            dataType: 'boolean',
        },
        charityOrganization_read: {
            title: '🔍 Spendenorganisation',
            dataType: 'boolean',
        },
        charityOrganization_write: {
            title: '📝 Spendenorganisation',
            dataType: 'boolean',
        },
        logging_read: {
            title: '🔍 Logs',
            dataType: 'boolean',
        },
        logging_write: {
            title: '📝 Logs',
            dataType: 'boolean',
        },
    },
})
