import { buildSchema, buildProperty } from '@camberi/firecms'
import { Faq } from '../types/faq.types'

export const faqSchema = buildSchema<Faq>({
    name: 'FAQ',
    properties: {
        question: {
            title: 'Frage',
            validation: { required: true },
            dataType: 'string',
        },
        answer: {
            title: 'Antwort',
            validation: { required: true },
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        sortOrder: {
            title: 'Sortierung',
            validation: { required: true },
            dataType: 'number',
        },
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 20,
            }),
    },
})
