import { buildCollection } from '@camberi/firecms'
import { addressSchema } from '../schemas/address.schema'
import { fetchGeopoint } from '../api/geo-point'

export const discountAddressesCollection = buildCollection({
    group: 'Vergünstigungen',
    path: 'discountAddresses',
    schema: addressSchema,
    name: 'Adressen',
    defaultSize: 'xs',
    initialSort: ['name', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
