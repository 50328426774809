import { buildSchema, buildProperty } from '@camberi/firecms'
import { User } from '../types/user.types'

export const userSchema = buildSchema<User>({
    name: 'User',
    properties: {
        lastname: {
            title: 'Nachname',
            validation: { required: true },
            dataType: 'string',
        },
        firstname: {
            title: 'Vorname',
            validation: { required: true },
            dataType: 'string',
        },
        email: {
            title: 'Email',
            validation: { required: true },
            dataType: 'string',
        },
        lastOnline: {
            title: 'Zuletzt Online',
            description: 'Zeitpunkt, wenn Signup das letzte Mal Online war',
            dataType: 'timestamp',
        },
        ageCategory: {
            title: 'Alterklasse',
            validation: { required: true },
            dataType: 'string',
            config: {
                enumValues: {
                    over18: 'Ü18',
                    under18: 'U18',
                    under16: 'U16',
                },
            },
            columnWidth: 150,
        },
        roles: {
            dataType: 'array',
            title: 'Rollen',
            validation: { required: true },
            of: {
                dataType: 'reference',
                path: 'roles',
            },
        },
    },
})
