import {buildSchema} from "@camberi/firecms";
import {LoggingTypes} from "../types/loggingTypes";
import {AdditionalInfoPreview} from "../components/additional-info-preview";

export const loggingSchema = buildSchema<LoggingTypes>({
    name: 'Logging',
    properties: {
        message: {
            title: 'Log Nachricht',
            dataType: 'string',
        },
        additional: {
            config: {
                Preview: AdditionalInfoPreview,
            },
            title: 'Zusätzliche Information',
            dataType: 'array',
        },
        timestamp: {
            title: 'Datum',
            dataType: 'string',
        },
        fileName: {
            title: 'Filename',
            dataType: 'string',
            columnWidth: 200
        },
        columnNumber: {
            title: 'Column Number',
            dataType: 'number',
        },
        lineNumber: {
            title: 'Line Number',
            dataType: 'number',
        },
        level: {
            title: 'Level',
            dataType: 'number',
        },
    },
})