import {buildSchema} from '@camberi/firecms'
import {MainSettings} from '../../types/events/settings.types'
import AgeIcon from "../../preview/ageIcon/ageIcon";

export const mainSettingsSchema = buildSchema<MainSettings>({
    name: 'Einstellungen',
    properties: {
        show: {
            title: 'Features anzeigen',
            description: 'Definiert welche Features angezeigt werden sollen bzw. vorhanden sind.',
            longDescription: 'Unser Event-System bietet viele verschieden Komponente an, um das Anzeigen von Informationen, passend zu vereinfachen. Hier soll definiert werden, welche Features angezeigt werden sollen bzw. mit Daten gefüllt sind.',
            dataType: 'map',
            validation: {required: true},
            properties: {
                program: {
                    title: 'Programm',
                    dataType: 'boolean',
                },
                menu: {
                    title: 'Menü',
                    dataType: 'boolean',
                },
                activities: {
                    title: 'Aktivitäten',
                    dataType: 'boolean',
                },
                faq: {
                    title: 'FAQ',
                    dataType: 'boolean',
                },
                impressions: {
                    title: 'Impressionen',
                    dataType: 'boolean',
                },
                partners: {
                    title: 'Partner',
                    dataType: 'boolean',
                },
                sitePlans: {
                    title: 'Geländepläne',
                    dataType: 'boolean',
                }
            }
        },
        allow: {
            title: 'Erlauben',
            dataType: 'map',
            properties: {
                scanningStart: {
                    title: 'Scannen durch Scanner',
                    description: 'Definiert, ob die Anmeldungen mit der Scanner Rolle, die Anmeldungen von Besuchern scannen dürfen.',
                    dataType: 'boolean',
                },
            }
        },
        registerWithTicket: {
            title: 'Registrierung mit Ticket',
            description: 'Definiert, ob das Event, über das MA-Ticket System läuft.',
            dataType: 'boolean',
        },
        under16Color: {
            title: 'Farbe unter 16',
            description: 'Definiert die Farbe vom Scanning Hintergrund für unter 16 jährige.',
            dataType: 'string',
            columnWidth: 200,
            validation: {required: true},
            config: {
                Preview: AgeIcon
            }
        },
        under18Color: {
            title: 'Farbe unter 18',
            description: 'Definiert die Farbe vom Scanning Hintergrund für unter 18 jährige.',
            dataType: 'string',
            columnWidth: 200,
            validation: {required: true},
            config: {
                Preview: AgeIcon
            }
        },
        over18Color: {
            title: 'Farbe über 18',
            description: 'Definiert die Farbe vom Scanning Hintergrund für über 18 jährige.',
            dataType: 'string',
            columnWidth: 200,
            validation: {required: true},
            config: {
                Preview: AgeIcon
            }
        },
    },
})
