import {buildSchema} from '@camberi/firecms'
import {Partner} from '../../types/events/partner.types'

export const partnerSchema = buildSchema<Partner>({
    name: 'Partner',
    defaultValues: {
        published: true,
    },
    properties: {
        title: {
            title: 'Name',
            dataType: 'string',
            validation: {required: true, min: 5, max: 120},
        },
        description: {
            title: 'Beschreibung',
            dataType: 'string',
            validation: {required: false, min: 5, max: 1200},
            config: {
                markdown: true,
            },
        },
        image: {
            title: 'Bild',
            dataType: 'string',
            validation: {required: true},
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'events/partner',
                    acceptedFiles: ['image/*'],
                    metadata: {
                        cacheControl: 'max-age=1000000',
                    },
                },
            },
        },

        sortOrder: {
            title: 'Sortierung',
            dataType: 'number',
            validation: {required: true, min: 0, max: 100},
        },
        url: {
            title: 'URL',
            dataType: 'string',
            validation: {required: true, min: 5, max: 300},
        },
        published: {
            title: 'Anzeigen',
            dataType: 'boolean',
            validation: {required: true},
        },
    },
})
