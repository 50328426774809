import { buildSchema, buildProperty } from '@camberi/firecms'
import { AppSetting } from '../types/appsetting.types'

export const appsettingSchema = buildSchema<AppSetting>({
    name: 'PV-App Settings',
    properties: {
        moreAboutPVText: buildProperty({
            title: 'Über PV Text',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        }),
        statutesPDFUrl: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Statuten PDF',
            dataType: 'string',
            config: {
                storageMeta: {
                    storagePath: 'pdf',
                    acceptedFiles: ['application/pdf'],
                },
            },
        }),
        charityOrganizationCardTitle: buildProperty({
            title: 'Spendenorganisation Karte Titel',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        }),
        charityOrganizationCardText: buildProperty({
            title: 'Spendenorganisation Karte Text',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        }),
        charityOrganizationCardImage: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Spendenorganisation Karte Bild',
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/settings',
                    acceptedFiles: ['image/*'],
                },
            },
            columnWidth: 200,
        }),
        showCharityOrganizationCard: ({ values }) =>
            buildProperty({
                title: 'Spendenorganisation Karte',
                description: 'Anzeige der Spendenorganisation Karte auf der Startseite',
                dataType: 'boolean',
                columnWidth: 180,
            }),
        showLatestDiscountCard: ({ values }) =>
            buildProperty({
                title: 'Letzter hinzugefügte Vergünstigung',
                description: 'Um die letzte hinzugefügte Vergünstigungskarte anzuzeigen (falls eine vorhanden ist)',
                dataType: 'boolean',
                columnWidth: 180,
            }),
        ictInfoLink: ({ values }) =>
        buildProperty({
            title: 'ict-info Link',
            description: 'Link zur ICTinfo Seite',
            dataType: 'string',
            columnWidth: 180,
        }),
    },
})
