import {buildSchema} from '@camberi/firecms'
import {Member, MemberRoles} from "../../types/associations/member.types";

export const memberSchema = buildSchema<Member>({
    name: 'Mitglieder',
    customId: true,
    properties: {
        joined: {
            title: 'Beitritt',
            dataType: 'timestamp',
            validation: {required: true},
            readOnly: true,
            autoValue: 'on_create'
        }, role: {
            title: 'Rolle', validation: {required: true}, dataType: 'number', config: {
                enumValues: {
                    [MemberRoles.sa]: 'Superadmin',
                    [MemberRoles.aa]: 'Vereins-Admin',
                    [MemberRoles.ame]: 'Events-Manager',
                    [MemberRoles.amm]: 'Mitglieder-Manager',
                    [MemberRoles.am]: 'Mitglied',
                }
            }
        }
    }
})