import { buildCollection } from '@camberi/firecms'
import { charityOrganizationSchema } from '../schemas/charityorganization.schema'

export const charityOrganizationCollection = buildCollection({
    group: 'Personalverein',
    path: 'charityOrganization',
    schema: charityOrganizationSchema,
    name: 'Spendenorganisationen',
    defaultSize: 'xs',
    initialSort: ['name', 'asc'],
    textSearchEnabled: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
