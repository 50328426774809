import { buildSchema, buildProperty } from '@camberi/firecms'
import { PushTopic } from '../types/pushtopic.types'

export const pushTopicSchema = buildSchema<PushTopic>({
    name: 'Push-Topic',
    customId: true,
    properties: {
        topic: {
            title: 'Topic Name',
            validation: { required: true },
            dataType: 'string',
            columnWidth: 250,
        },
        autoSubscribe: ({ values }) =>
            buildProperty({
                title: 'Automatisch abonnieren',
                dataType: 'boolean',
                columnWidth: 180,
            }),
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 130,
            }),
    },
})
