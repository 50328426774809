import React, {ReactElement} from 'react';
import './ageIcon.css';
import {PreviewComponentProps} from "@camberi/firecms";
function AgeIcon({value, property, size}: PreviewComponentProps<string>): ReactElement {
    const style = {
        backgroundColor: value,
    };
    return (
        <>
        <div className="ageIconShape" style={style}>
        <div className="ageIconText">AGE</div>
        </div>
</>
)
}

export default AgeIcon;