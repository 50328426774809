import { buildSchema, buildProperty } from '@camberi/firecms'
import { PublicAppSetting } from '../types/publicAppsetting.types'

export const publicAppsettingSchema = buildSchema<PublicAppSetting>({
    name: 'Öffentliche Einstellung',
    properties: {
        forceUpdateVersion: buildProperty({
            title: 'Force Update > Version',
            description:
            'Mit einem Forced Update können Benutzer einer gewissen Version auf eine neue forciert werden. In der alten App erscheint Screen mit Button zum App Store',
            dataType: 'string',
            columnWidth: 200,
        }),
        welcomeScreenTitle: buildProperty({
            title: 'Willkommensseite Titel',
            description: 'Titel auf der Willkommensseite',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        }),
        welcomeScreenText: buildProperty({
            title: 'Willkommensseite Text',
            description: 'Text auf der Willkommensseite',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        }),
        showMessage: ({ values }) =>
            buildProperty({
                title: 'MM: Anzeigen',
                dataType: 'boolean',
                columnWidth: 100,
            }),
        maintMessageTitle: buildProperty({
            title: 'MM: Titel',
            description: 'Wartungsmeldung Titel',
            dataType: 'string',
            config: {
                markdown: false,
            },
        }),
        maintMessageText: buildProperty({
            title: 'MM: Text',
            description: 'Text in der Wartungsmeldung',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        }),
        maintMessageType: {
            title: 'MM: Typ',
            description: 'Typ der übergeordete Wartungsmeldung',
            dataType: 'string',
            config: {
                enumValues: {
                    info: 'Info',
                    warning: 'Warning',
                    error: 'Error',
                    success: 'Success',
                },
            },
        },
        showActionButton: ({ values }) =>
            buildProperty({
                title: 'MM: CTA-Button anzeigen',
                description:
                    'Soll ein Call-To-Action (CTA) Button in der Wartungsmeldung angezeigt werden',
                dataType: 'boolean',
                columnWidth: 140,
            }),
        actionButtonName: buildProperty({
            title: 'MM: CTA-Button Name',
            description: 'Name des CTA-Buttons in der MM',
            dataType: 'string',
            config: {
                markdown: false,
            },
        }),
        actionLink: buildProperty({
            title: 'MM: CTA-Button Link',
            description:
                'Link welcher aufgerufen wird, wenn auf CTA gedrückt wird',
            dataType: 'string',
            config: {
                markdown: false,
            },
        }),
        legalNoticeUrl: buildProperty({
            title: 'Link für Rechtlichs',
            description:
                'Link zu den rechtlichen Bedingungen und Hinweisen.',
            dataType: 'string',
            config: {
                markdown: false,
            },
            validation: { url: true }
        }),
        privacyPolicyUrl: buildProperty({
            title: 'Link für Rechtlichs',
            description:
                'Link zu den rechtlichen Bedingungen und Hinweisen.',
            dataType: 'string',
            config: {
                markdown: false,
            },
            validation: { url: true }
        }),
    },
})
