import {buildSchema} from '@camberi/firecms'
import {Faq} from '../../types/events/faq.types'

export const faqSchema = buildSchema<Faq>({
    name: 'FAQ',
    defaultValues: {
        published: true,
    },
    properties: {
        sortOrder: {
            title: 'Sortierung',
            validation: {required: true, min: 0, max: 100},
            dataType: 'number',
        },
        question: {
            title: 'Frage',
            validation: {required: true, min: 5, max: 300},
            dataType: 'string',
        },
        answer: {
            title: 'Antwort',
            validation: {required: true, min: 5, max: 1200},
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        published: {
            title: 'Anzeigen',
            dataType: 'boolean',
            columnWidth: 200,
            validation: {required: true},
        },
    },
})
