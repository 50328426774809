import { buildCollection } from '@camberi/firecms'
import { pushTopicSchema } from '../schemas/pushnotification.schema'

export const pushTopicsCollection = buildCollection({
    group: 'Verwaltung',
    path: 'pushTopics',
    schema: pushTopicSchema,
    name: 'Push-Themen',
    initialSort: ['topic', 'asc'],
    defaultSize: 'xs',
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
