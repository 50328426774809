import { buildProperty, buildSchema } from '@camberi/firecms'
import { Event } from '../../types/events/event.types'

export const eventSchema = buildSchema<Event>({
    name: 'Event',
    description: 'Ein Event, deines Vereins, mit vielen Funktionen die unterschiedlich und anpassbar für jedes Event sind.',
    defaultValues: {
        published: true,
        canBeRegisteredFor: true,
    },
    properties: {
        name: {
            title: 'Name',
            dataType: 'string',
            columnWidth: 300,
            validation: { required: true, max: 70 }
        },
        slogan: {
            title: 'Slogan',
            description: 'Wird in den Übersichten angezeigt.',
            longDescription: 'Motiviere die Leute, sich für das Event anzumelden! Kurz und bündig, soll hier das Event angepriesen werden.',
            dataType: 'string',
            columnWidth: 300,
            validation: { required: true, max: 150 }
        },
        description: {
            title: 'Beschreibung',
            description: 'Wird auf der Detailseite angezeigt und sollte über das Event informieren.',
            longDescription: 'Hier kann eine ausführliche Beschreibung des Events angegeben werden, am besten mit einem guten Touch Humor. Es können auch Markdown-Elemente verwendet werden. Informationen wie Program bzw. Ablauf, Speisen, Aktivitäten, FAQ, etc. sollen aber in den entsprechenden Komponenten angegeben werden.',
            dataType: 'string',
            columnWidth: 370,
            validation: { required: true, max: 1800 },
            config: {
                markdown: true
            }
        },
        banner: buildProperty({
            title: 'Banner',
            dataType: 'string',
            columnWidth: 300,
            validation: { required: true },
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/events',
                    validation: { required: true },
                    acceptedFiles: ['image/*']
                }
            }
        }),
        start: {
            title: 'Startdatum',
            dataType: 'timestamp',
            validation: { required: true }
        },
        end: {
            title: 'Enddatum',
            dataType: 'timestamp',
            validation: { required: true }
        },
        location: {
            title: 'Ort',
            description: 'Ort an dem das Event stattfindet.',
            longDescription: 'Hier soll der Ort angegeben werden, an dem das Event stattfindet. Falls es mehrere Orte gibt, soll der Hauptort angegeben werden.',
            dataType: 'map',
            validation: { required: true },
            properties: {
                name: {
                    title: 'Name',
                    description: 'z.B. Kultur und Kongresszentrum, Eisfeld, Kino Bourbaki, etc.',
                    dataType: 'string',
                    validation: { required: true}
                },
                address: {
                    title: 'Addresse',
                    description: 'z.B Pilatusstrasse 12',
                    dataType: 'string',
                    validation: { required: true }
                },
                postalCode: {
                    title: 'Postleitzahl',
                    description: 'z.B. 6003',
                    dataType: 'string',
                    validation: { required: true, min: 4, max: 6 }
                },
                city: {
                    title: 'Stadt',
                    description: 'z.B. Luzern',
                    dataType: 'string',
                    validation: { required: true }
                },
                url: {
                    title: 'Google Maps URL',
                    description: 'Fallback, falls die Adresse nicht automatisch gefunden werden kann.',
                    dataType: 'string',
                    config: {
                        url: true,
                    },
                    validation: { required: false}
                }
            }
        },
        registration: {
            title: 'Anmeldung',
            dataType: 'map',
            properties: {
                description: {
                    title: 'Beschreibung',
                    description: 'Text der über der Anmeldung angezeigt wird, z.B "Anmeldungen sind verbindlich und können nicht mehr storniert werden."',
                    dataType: 'string',
                    validation: { required: false, max: 300 }
                },
                deadline: {
                    title: 'Anmeldeschluss',
                    description: 'Ab wann User sich nicht mehr per App anmelden können, z.B. 2 Tage vor dem Event',
                    dataType: 'timestamp',
                    validation: { required: true }
                }
            }
        },
        deregistration: {
            title: 'Abmeldung',
            dataType: 'map',
            properties: {
                description: {
                    title: 'Beschreibung',
                    description: 'Text der vor der Abmeldung angezeigt wird, z.B. Abmeldungen sind verbindlich und werden bei Nichterscheinen verrechnet. Muss vor oder gleichzeitig dem Eventstart sein!',
                    dataType: 'string',
                    validation: { required: false, max: 300 }
                },
                deadline: {
                    title: 'Abmeldeschluss',
                    description: 'Ab wann User sich nicht mehr per App abmelden können, z.B. 2 Tage vor dem Event. Muss vor oder gleichzeitig dem Eventstart sein!',
                    dataType: 'timestamp',
                    validation: { required: true }
                }
            }
        },
        partsDescription: {
            title: 'Infotext für mehrteilige Anmeldungen',
            dataType: 'map',
            properties: {
                de: {
                    title: 'DE',
                    dataType: 'string',
                    validation: { required: false },
                },
                en: {
                    title: 'EN',
                    dataType: 'string',
                    validation: { required: false },
                }
            }
        },
        parts: {
            title: 'Mehrteilige Anmeldungen',
            description: 'Für welche Teile Besuchende sich anmelden können.',
            dataType: 'array',
            validation: {required: false},
            of: {
                title: 'Anmeldungsteil',
                dataType: 'map',
                validation: {required: true},
                properties: {
                    name: {
                        title: 'Bezeichnung für Eventteil (muss einmalig sein)',
                        dataType: 'string',
                        validation: {required: true, min: 3},
                    },
                    start: {
                        title: 'Datum',
                        dataType: 'timestamp',
                        validation: {required: true},
                    },
                    canBeRegisteredFor: {
                        title: 'Sichtbar',
                        dataType: 'boolean',
                    },
                }
            },
        },
        publicTransportTicket: {
            title: 'ÖV Tickets',
            dataType: 'map',
            properties: {
                show: {
                    title: 'ÖV Ticket anzeigen',
                    dataType: 'boolean',
                },
                description: {
                    title: 'Beschreibung',
                    description: 'Text der über den Bildern angezeigt wird, z.B. für Infos oder Gültigkeitshinweise.',
                    dataType: 'map',
                    properties: {
                        de: {
                            title: 'DE',
                            dataType: 'string',
                            validation: { required: false },
                        },
                        en: {
                            title: 'EN',
                            dataType: 'string',
                            validation: { required: false },
                        }
                    }
                },
                images: {
                    title: 'Bilder',
                    description: 'z.B. für Transportunternehmen',
                    dataType: 'array',
                    validation: { required: false },
                    of: {
                        dataType: 'string',
                        config: {
                            storageMeta: {
                                mediaType: 'image',
                                storagePath: 'images/events',
                                acceptedFiles: ['image/*'],
                            },
                        },
                    },
                }
            }
        },
        contact: {
            title: 'Kontakt',
            description: 'Kontaktperson für das Event.',
            dataType: 'map',
            properties: {
                name: {
                    title: 'Name',
                    description: 'z.B. Max Muster',
                    dataType: 'string',
                    validation: { required: true, max: 60 }
                },
                email: {
                    title: 'E-Mail',
                    description: 'z.B. max.muster@lukb.ch',
                    dataType: 'string',
                    validation: { required: true, max: 60 }
                },
                phone: {
                    title: 'Telefonnummer',
                    description: 'z.B. 041 123 45 67',
                    dataType: 'string',
                    validation: { required: true, matches: /^(\+\d{2})?\s?((\(0\))|0)?[0-9\s]{9,12}$/ }
                     
                },
                role: {
                    title: 'Funktion / Rolle',
                    description: 'z.B. Eventverantwortlicher',
                    dataType: 'string',
                    validation: { required: true, max: 60 }
                }
            }
        },
        published: buildProperty({
            title: 'Sichtbar',
            dataType: 'boolean',
            columnWidth: 200,
            validation: { required: true }
        }),
    }
});
