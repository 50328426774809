import {buildProperty, buildSchema} from '@camberi/firecms'
import {Activities} from '../../types/events/activities.types'

export const activitySchema = buildSchema<Activities>({
    name: 'Aktivitäten',
    defaultValues: {
        published: true,
    },
    properties: {
        title: {
            title: 'Titel',
            dataType: 'string',
            validation: {required: true, min: 5, max: 120},
        },
        description: {
            title: 'Beschreibung',
            dataType: 'string',
            validation: {required: false, min: 5, max: 1500},
            config: {
                markdown: true,
            },
        },
        iconName: {
            title: 'Mat-Icon Name',
            description: 'ID des Icons von Material Design Icons',
            dataType: 'string',
            validation: {required: false, min: 1, max: 120},
        },
        image: buildProperty({
            title: 'Banner',
            description: 'Bannerbild der Aktivitätskarte',
            dataType: 'string',
            validation: {required: false},
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/events',
                    acceptedFiles: ['image/*'],
                },
            },
        }),
        sortOrder: {
            title: 'Sortierung',
            dataType: 'number',
            validation: {required: true, min: 0, max: 100},
        },
        published: {
            title: 'Anzeigen',
            dataType: 'boolean',
            columnWidth: 200,
            validation: {required: true},
        },
    },
})
