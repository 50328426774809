import { buildSchema, buildProperty } from '@camberi/firecms'
import { CharityOrganization } from "../types/charityorganization.types";

export const charityOrganizationSchema = buildSchema<CharityOrganization>({
    name: 'Spendenorganisation',
    properties: {
        name: {
            title: 'Name',
            validation: {required: true},
            dataType: 'string',
        },
        description: {
            title: 'Beschreibung',
            validation: {required: true},
            description: 'Gib eine Beschreibung ein.',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        },
        website: {
            title: 'Webseite',
            dataType: 'string',
        },
        logo: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Logo',
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/charityorganization',
                    acceptedFiles: ['image/*'],
                },
            },
            columnWidth: 200,
        }),
        published: ({values}) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 150,
            }),
        spendPvMoneyButtonText: {
            title: 'PV Betrag spenden Button Text',
            validation: {required: true, max: 28},
            dataType: 'string',
        },
        spendOwnMoneyButtonText: {
            title: 'Eigener Betrag spenden Button Text',
            validation: {required: true, max: 28},
            dataType: 'string',
        },
        successAlertText: {
            title: 'Dankesmeldung Text',
            description: 'Text welcher nach dem spenden des PV Geldes angezeigt wird.',
            validation: {required: true},
            dataType: 'string',
        },
    }
})
