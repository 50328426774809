import {FirestoreTextSearchController, performAlgoliaTextSearch} from "@camberi/firecms";
import algoliasearch, {SearchClient} from "algoliasearch";

const client: SearchClient = algoliasearch(
    process.env.REACT_APP_ANGOLIASEARCH_APPID || '',
    process.env.REACT_APP_ANGOLIASEARCH_APIKEY || ''
)

export const textSearchController: FirestoreTextSearchController = ({path, searchString}) => {
    const discountIndex = client.initIndex('discounts')
    const userIndex = client.initIndex('users')
    const loggingIndex = client.initIndex('logging')

    if (path === 'discounts')
        return performAlgoliaTextSearch(discountIndex, searchString)
    if (path === 'users')
        return performAlgoliaTextSearch(userIndex, searchString)
    if (path === 'logging')
        return performAlgoliaTextSearch(loggingIndex, searchString)
    return undefined
}