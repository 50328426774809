import {buildSchema} from '@camberi/firecms'
import {Menu} from '../../types/events/menu.types'

export const menuSchema = buildSchema<Menu>({
    name: 'Menü',
    defaultValues: {
        published: true,
    },
    properties: {
        title: {
            title: 'Titel',
            dataType: 'string',
            validation: {required: true, min: 5, max: 120},
        },
        description: {
            title: 'Beschreibung',
            dataType: 'string',
            validation: {required: false, min: 5, max: 1500},
            config: {
                markdown: true,
            },
        },
        iconName: {
            title: 'Icon ID',
            description: 'ID des Icons',
            dataType: 'string',
            validation: {required: false, min: 1, max: 120},
        },
        published: {
            title: 'Anzeigen',
            dataType: 'boolean',
            validation: {required: true},
        },
        sortOrder: {
            title: 'Sortierung',
            dataType: 'number',
            validation: {required: true, min: 0, max: 100},
        },
    },
})
