import { NavigationBuilder } from '@camberi/firecms'
import { boardmembersCollection } from '../collections/boardmembers.collection'
import { charityOrganizationCollection } from '../collections/charityOrganization.collection'
import { discountAddressesCollection } from '../collections/discountAddresses.collection'
import { discountBuildCollection } from '../collections/discounts.collection'
import { eventsCollection } from '../collections/events.collection'
import { faqCollection } from '../collections/faq.collection'
import { loggingCollection } from '../collections/logging.collection'
import { pushTopicsCollection } from '../collections/pushTopics.collection'
import { rolesCollection } from '../collections/roles.collection'
import { settingsCollection } from '../collections/settings.collection'
import { settingsPublicCollection } from '../collections/settingsPublic.collection'
import { usersCollection } from '../collections/users.collection'
import {associationsCollection} from "../collections/associations.collection";

export const navigation: NavigationBuilder = async () => {
    return {
        collections: [
            discountBuildCollection,
            discountAddressesCollection,
            eventsCollection,
            boardmembersCollection,
            faqCollection,
            usersCollection,
            settingsCollection,
            settingsPublicCollection,
            pushTopicsCollection,
            rolesCollection,
            charityOrganizationCollection,
            loggingCollection,
            associationsCollection
        ],
    }
}
