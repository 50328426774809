import { PreviewComponentProps } from "@camberi/firecms";
import { ReactElement } from "react";

export function AdditionalInfoPreview(value: PreviewComponentProps<any>): ReactElement {
    return (
        <div>
            {value.value.map((additional: any) => {
                return (
                    <div>
                        {additional}
                        <br />
                    </div>
                );
            })}
        </div>
    );
}