import { buildProperty, buildSchema } from '@camberi/firecms'
import { PVEvent } from '../types/event.types'

export const eventSchema = buildSchema<PVEvent>({
    name: 'Event',
    properties: {
        name: {
            title: 'Name',
            validation: { required: true },
            dataType: 'string',
        },
        dateStart: {
            title: 'Datum: Start',
            validation: { required: true },
            dataType: 'timestamp',
        },
        dateEnd: {
            title: 'Datum: Ende',
            dataType: 'timestamp',
        },
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 130,
            }),
        image: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Bild',
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/events',
                    acceptedFiles: ['image/*'],
                },
            },
        }),

        description: {
            title: 'Beschreibung',
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        eventFor: {
            title: 'Für wer?',
            dataType: 'string',
        },
        location: {
            title: 'Ort',
            dataType: 'string',
        },
        locationUrl: {
            title: 'Ort (Link)',
            dataType: 'string',
        },
        registrationText: {
            title: 'Registrierung',
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        sendPushNotificationToUser: ({ values }) =>
            buildProperty({
                title: 'Push Notification an Signup',
                dataType: 'boolean',
                columnWidth: 100,
            }),
    },
})
