import { buildCollection } from '@camberi/firecms'
import { boardmemberSchema } from '../schemas/boardmember.schema'

export const boardmembersCollection = buildCollection({
    group: 'Personalverein',
    path: 'boardmembers',
    schema: boardmemberSchema,
    name: 'Vereinsleitung',
    defaultSize: 'xs',
    initialSort: ['lastname', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})
