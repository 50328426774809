import { BrowserRouter as Router } from 'react-router-dom'
import { EmailAuthProvider } from 'firebase/auth'

import {
    FirebaseAuthDelegate,
    CircularProgressCenter,
    FirebaseLoginView,
    FireCMS,
    NavigationRoutes,
    Scaffold,
    SideEntityDialogs,
    useFirebaseStorageSource,
    useFirebaseAuthDelegate,
    useFirestoreDataSource,
    useInitialiseFirebase,
} from '@camberi/firecms'

import 'typeface-rubik'
import 'typeface-space-mono'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { firebaseConfig } from './config/firebase.config'
import { navigation } from './config/navigation'
import { authenticator } from './config/authenticator'
import { textSearchController } from './config/textSearchController'
import { defaultTheme } from './config/theme'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

export default function App() {
    const {
        firebaseApp,
        firebaseConfigLoading,
        configError,
        firebaseConfigError,
    } = useInitialiseFirebase({
        firebaseConfig,
        onFirebaseInit: async (config) => {
            if (window.location.hostname === 'localhost') {
                const authEmulatorUrl = 'http://127.0.0.1:9099'
                await fetch(authEmulatorUrl)
                const auth = getAuth()
                connectAuthEmulator(auth, authEmulatorUrl)

                const firestore = getFirestore()
                connectFirestoreEmulator(firestore, 'localhost', 4002)
            }
        },
    })
    const signInOptions = [EmailAuthProvider.PROVIDER_ID]
    const authDelegate: FirebaseAuthDelegate = useFirebaseAuthDelegate({
        firebaseApp,
        signInOptions,
    })

    //Gets FireCMS error and extracts the JSON of it to set it as the new error.
    if (
        authDelegate.authError &&
        authDelegate.authError.message &&
        authDelegate.authError.message.includes(
            'HTTP Cloud Function returned an error:'
        )
    ) {
        const jsonError = extractError(authDelegate.authError.message)
        if (jsonError !== null) {
            authDelegate.authError.message = jsonError.error.message
        }
    }

    const dataSource = useFirestoreDataSource({
        firebaseApp: firebaseApp,
        textSearchController: textSearchController,
    })
    const storageSource = useFirebaseStorageSource({ firebaseApp: firebaseApp })

    if (configError) {
        return <div> {configError} </div>
    }

    if (firebaseConfigError) {
        return (
            <div>
                It seems like the provided Firebase config is not correct. If
                you are using the credentials provided automatically by Firebase
                Hosting, make sure you link your Firebase app to Firebase
                Hosting.
            </div>
        )
    }

    if (firebaseConfigLoading || !firebaseApp) {
        return <CircularProgressCenter />
    }

    return (
        <Router>
            <FireCMS
                navigation={navigation}
                authDelegate={authDelegate}
                authentication={authenticator}
                dataSource={dataSource}
                storageSource={storageSource}
                locale="de"
                dateTimeFormat="dd. MMMM yyyy HH:mm"
                entityLinkBuilder={({ entity }) =>
                    `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`
                }
            >
                {({ context, mode, loading }) => {
                    const theme = defaultTheme

                    let component
                    if (loading) {
                        component = <CircularProgressCenter />
                    } else if (!context.authController.canAccessMainView) {
                        component = (
                            <FirebaseLoginView
                                logo={'/assets/pv-logo-1024.png'}
                                signInOptions={[
                                    {
                                        provider: EmailAuthProvider.PROVIDER_ID,
                                    },
                                ]}
                                firebaseApp={firebaseApp}
                                authDelegate={authDelegate}
                            />
                        )
                    } else {
                        component = (
                            <Scaffold
                                name={
                                    'LUKB PV-App: Admin UI / ' +
                                    process.env.REACT_APP_ENV?.toUpperCase()
                                }
                                logo={'/assets/pv-logo-64.png'}
                            >
                                <NavigationRoutes />
                                <SideEntityDialogs />
                            </Scaffold>
                        )
                    }

                    return (
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            {component}
                        </ThemeProvider>
                    )
                }}
            </FireCMS>
        </Router>
    )
}

//This functions Extracts the JSON Error from firebase as FireCMS puts a String in front of the JSON
function extractError(str: string) {
    const firstOpen = str.indexOf('{', 1)
    do {
        const firstClose = str.lastIndexOf('}')
        if (firstClose <= firstOpen) {
            return null
        }
        do {
            const candidate = str.substring(firstOpen, firstClose + 1)
            try {
                const res = JSON.parse(candidate)
                return res
            } catch (e) {
                return JSON.parse("{message: 'an unexpected error occoured'}")
            }
        } while (firstClose > firstOpen)
    } while (firstOpen !== -1)
}
