import {buildSchema} from '@camberi/firecms'
import {Siteplan} from '../../types/events/siteplan.types'

export const siteplanSchema = buildSchema<Siteplan>({
    name: 'Lageplan',
    defaultValues: {
        published: true,
    },
    properties: {
        title: {
            title: 'Titel',
            validation: {required: true, min: 5, max: 120},
            dataType: 'string',
        },
        description: {
            title: 'Beschreibung',
            validation: {required: false, min:51, max: 1500},
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        image: {
            title: 'Bild',
            validation: {required: true},
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'events/siteplan',
                    acceptedFiles: ['image/*'],
                    metadata: {
                        cacheControl: 'max-age=1000000',
                    },
                },
            },
        },

        sortOrder: {
            title: 'Sortierung',
            validation: {required: true, min: 0, max: 100},
            dataType: 'number',
        },
        published: {
            title: 'Anzeigen',
            validation: {required: true},
            dataType: 'boolean',
        },
    },
})
