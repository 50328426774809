import { Authenticator } from '@camberi/firecms';
import { User as FirebaseUser } from '@firebase/auth';
import { userSchema } from '../schemas/user.schema';

export const authenticator: Authenticator<FirebaseUser> = async ({
    user,
    dataSource,
}) => {
    if (!user?.uid) return false;

    try {
        const entity = await dataSource.fetchEntity({
            path: `/users`,
            entityId: user.uid,
            schema: userSchema,
        });

        if (!entity || !entity.values) {
            throw new Error('Entity not found or entity.values not defined');
        }

        if (
            entity &&
            entity.values &&
            entity.values.roles &&
            Array.isArray(entity.values.roles)
        ) {
            const hasAdminRole = entity.values.roles.some(
                (role: { id: string, path: string }) => role.id === 'admin'
            );

            if (hasAdminRole) {
                return true;
            }
        }
    } catch (error) {
        console.error('Error in authenticator:', error);
    }

    throw new Error(
        'ERROR: Only admins allowed. Please contact your developer!'
    );
};
