import {buildSchema} from '@camberi/firecms'
import {Impression} from '../../types/events/impression.types'

export const impressionSchema = buildSchema<Impression>({
    name: 'Impression',
    defaultValues: {
        published: true,
    },
    properties: {
        image: {
            title: 'Bild',
            dataType: 'string',
            validation: {required: true},
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'events/impression',
                    acceptedFiles: ['image/*'],
                    metadata: {
                        cacheControl: 'max-age=1000000',
                    },
                },
            },
        },
        sortOrder: {
            title: 'Sortierung',
            dataType: 'number',
            validation: {required: true, min: 0, max: 100},
        },
        published: {
            title: 'Anzeigen',
            dataType: 'boolean',
            validation: {required: true},
        },
    },
})
