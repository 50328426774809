import { buildProperty, buildSchema } from '@camberi/firecms'
import { BoardMember } from '../types/boardmember.types'

export const boardmemberSchema = buildSchema<BoardMember>({
    name: 'Vorstandmitglied',
    properties: {
        lastname: {
            title: 'Nachname',
            validation: { required: true },
            dataType: 'string',
        },
        firstname: {
            title: 'Vorname',
            validation: { required: true },
            dataType: 'string',
        },
        jobDescription: {
            title: 'Funktion',
            dataType: 'string',
        },
        email: {
            title: 'Email',
            dataType: 'string',
        },
        phone: {
            title: 'Telefon',
            dataType: 'string',
        },
        image: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Bild',
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/boardmembers',
                    acceptedFiles: ['image/*'],
                },
            },
        }),
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 130,
            }),
    },
})
