import {allergies, eatStyle} from "./food.types";

export type Signup = {
    role: number extends EventUserRoles ? EventUserRoles : number
    allergies: Array<allergies>
    eatStyle: eatStyle
    notice: string
    signedUpDate: Date
    parts?: Array<string>;
    scans?: Array<{ byUser: string; date: Date }>
}

export enum EventUserRoles {
    visitor = 1,
    scanner = 2,
    admin = 3,
}
