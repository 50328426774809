import {buildSchema} from '@camberi/firecms'
import {EventUserRoles, Signup} from '../../types/events/signup.types'
import {eatStyle} from "../../types/events/food.types";

export const signupSchema = buildSchema<Signup>({
    name: 'Anmeldung',
    description: 'Eine Anmeldung für ein Event',
    properties: {
        role: {
            title: 'Rolle',
            description: 'Die Rolle des Benutzers',
            dataType: 'number',
            validation: {required: true},
            config: {
                enumValues: {
                    [EventUserRoles.visitor]: 'Besucher',
                    [EventUserRoles.scanner]: 'Scanner',
                    [EventUserRoles.admin]: 'Admin',
                },
            },
        },
        eatStyle: {
            title: 'Essensstil',
            dataType: 'string',
            validation: {required: true},
            config: {
                enumValues: {
                    [eatStyle.omnivore]: 'Alles',
                    [eatStyle.vegetarian]: 'Vegetarisch',
                    [eatStyle.vegan]: 'Vegan',
                    [eatStyle.pescetarian]: 'Pescetarisch',
                    [eatStyle.other]: 'Andere',
                }
            }
        },
        allergies: {
            title: 'Allergien',
            dataType: 'array',
            validation: {required: false},
            of: {
                title: 'Allergie',
                dataType: 'string',
                validation: {required: false},
            },
        },
        notice: {
            title: 'Bemerkung',
            dataType: 'string',
            validation: {
                required: false,
                min: 0,
                max: 500,
            },

        },
        signedUpDate: {
            title: 'Anmeldedatum',
            dataType: 'timestamp',
            validation: {required: true},
        },
        parts: {
            title: 'Mehrteilige Anmeldungen',
            description: 'Hier werden die IDs der Teile gespeichert, an denen der Benutzer teilnimmt bzw. teilnehmen möchte.',
            longDescription: 'Wie wir wissen, kann man für ein Event mehrere Teile haben. Wenn das Event mehrere Teile hat, wird hier gespeichert, an welchen Teilen der Benutzer teilnimmt bzw. angegeben hat, an welchen Teilen er teilnehmen möchte.',
            dataType: 'array',
            validation: {required: false},
            of: {
                title: 'Teilnahme',
                description: 'ID des Eventteils',
                dataType: 'string',
                validation: {required: false},
            },
        },
        scans: {
            title: 'Scans',
            description: 'Scans des Benutzers',
            dataType: 'array',
            validation: {required: false},
            of: {
                title: 'Scan',
                description: 'Ein Scan des Benutzers, der von einem Scanner Benutzer durchgeführt wurde',
                dataType: 'map',
                properties: {
                    byUser: {
                        title: 'Von Scanner (ID)',
                        dataType: 'string',
                        validation: {required: true, min: 1, max: 300},
                    },
                    date: {
                        title: 'Datum',
                        dataType: 'timestamp',
                        validation: {required: true},
                    },
                },
            },
        },
    },
})
