import { buildSchema, buildProperty } from '@camberi/firecms'
import { Discount } from '../types/discount.types'

export const discountSchema = buildSchema<Discount>({
    name: 'Vergünstigung',
    properties: {
        name: {
            title: 'Name',
            validation: { required: true },
            dataType: 'string',
        },
        category: {
            title: 'Kategorie',
            validation: { required: true },
            dataType: 'string',
            config: {
                enumValues: {
                    car_bike: 'Auto & Velo',
                    misc: 'Diverses',
                    fitness_wellness: 'Fitness & Welness',
                    gastronomy: 'Gastronomie',
                    beauty_health: 'Körperpflege & Gesundheit',
                    food: 'Lebensmittel',
                    fashion: 'Mode & Accessoires',
                    hotel: 'Unterkunft',
                    living: 'Wohneinrichtung',
                },
            },
        },
        website: {
            title: 'Webseite',
            dataType: 'string',
        },
        description: {
            title: 'Beschreibung',
            description: 'Gib eine Beschreibung ein.',
            dataType: 'string',
            config: {
                markdown: true,
            },
            columnWidth: 300,
        },
        isOnline: ({ values }) =>
            buildProperty({
                title: 'Online Angebot?',
                dataType: 'boolean',
                columnWidth: 100,
            }),
        addresses: {
            dataType: 'array',
            title: 'Adressen',
            description: 'Adressen von Filialien oder Standorte',
            of: {
                dataType: 'reference',
                path: 'discountAddresses',
            },
        },
        logo: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Logo',
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/discounts',
                    acceptedFiles: ['image/*'],
                },
            },
            columnWidth: 200,
        }),
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 20,
            }),
        dateAdded: {
            title: 'Hinzugefügtes Datum',
            dataType: 'timestamp',
            disabled: true
        },
        sendPushNotificationToUser: ({ values }) =>
            buildProperty({
                title: 'Push Notification an Signup',
                dataType: 'boolean',
                columnWidth: 100,
            }),
    },
})
