import { buildCollection } from '@camberi/firecms'
import {loggingSchema} from "../schemas/logging.schema";

export const loggingCollection = buildCollection({
    group: '🔥 Superadmin-Zone',
    path: 'logging',
    schema: loggingSchema,
    name: 'Logging',
    defaultSize: 'xs',
    initialSort: ['timestamp', 'desc'],
    textSearchEnabled: true,
    excludedProperties: [
        'level'
    ],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: false,
        create: false,
        delete: true,
    }),
})
